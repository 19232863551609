import React from 'react'

import Layout from '../../components/layout'
import { SmallTitlePage } from '../../components/common/smallTitlePage'
import { BeautyTreatments } from '../../components/common/beautyTreatments'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'
const LodzLaserotherapy = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulLodzLaseroterapia(sort: {fields: kolejnosc}) {
          nodes {
            id
            zdjecieGlowne {
                gatsbyImageData(layout: CONSTRAINED)
            }
            nazwaZabiegu
            opisZabiegu {
              opisZabiegu
            }
            slug
          }
        },

        file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  const treatments = data.allContentfulLodzLaseroterapia.nodes

    return (
        <Layout lodz>
          <Seo
            title="Laseroterapia - BodyMed Group Lodz"
            description="Skuteczne zabiegi z zakresu laseroterapii w klinice BodyMed Group. Laserowe fotoodmładzanie, laserowe usuwanie blizn i rozstępów, depilacja laserowa. Zapraszamy!"
            url="https://bodymedgroup.pl/lodz-laseroterapia"
          />
          <SmallTitlePage 
            title='Laseroterapia' 
          />
            <BeautyTreatments treatments={treatments}/>
        </Layout>
    )
}

export default LodzLaserotherapy
